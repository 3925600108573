import React from 'react'
import { Link } from 'gatsby'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Pill from '../components/pill'
import PublicationCard from '../components/publicationCard'
import styles from '../styles/member.module.css'
import BackArrow from '../assets/back-arrow.svg'
import LinkedInIcon from '../assets/linkedIn.svg'
import WebsiteIcon from '../assets/website.svg'

const People = ({data}) => {

    const { airtablePeople:member } = data
	const {Photo:picture, RadXPublication:publications, display_name:name,  affiliation:institution, Project_Role:role, last_name:lastname, first_name:firstname} = member.data
    if(publications) {
        publications.forEach(publication => {
            publication.data.Date = new Date(publication.data.Date)
        })

    }
    return (
        <Layout>
            <Link to={`../`}><div className={styles.back}><BackArrow/><span>Back to network</span></div></Link>
            <div className={styles.member}>
                <div className={styles.header}>
                    {
                        picture && (<div className={styles.picture} style={{backgroundImage: `url(${picture[0].url})`}}/>)
                    }
                    <h2>{name}</h2>
                    <p>{institution?.[0].data.Name}</p>
                    <div>
                        {
                            role &&  role.split('\n').map((role, i) => <p key={i} className={styles.role}>{role}</p>)
                        }
                    </div>


                </div>
                {
                  publications && (
                        <>
                            <h3>Related publications</h3>
                            <div className={styles.publications}>
                                {
                                    publications.sort((a,b) => b.data.Date.getTime() - a.data.Date.getTime()).map( (publication, i) => (
                                      !publication.data.HideFromNetwork &&    (
                                        <PublicationCard key={i} title={publication.data.Title} author={publication.data.Author} source={publication.data.Source} date={publication.data.Date}
                                                         abstract={publication.data.Abstract}
                                                         highlightAuthor={[lastname,firstname]} />
                                    )
                                    )
                                    )
                                }
                            </div>
                        </>
                    )
                }

            </div>
        </Layout>
    )
}

export const query = graphql`
    query ($slug: String!) {
        airtablePeople(data: {Slug: {eq: $slug}}) {
            data {
                Photo {
                    url
                }
                RadXPublication {
                    data {
                        Title
                        Abstract
                        Source
                        Author
                        Date
                        HideFromNetwork
                    }
                }
                display_name
                affiliation{
                    data {
                        Name
                    }
                }
                
                first_name
                last_name
                Project_Role
                Slug
            }
        }
    }
`

export default People
